<template>
  <form @submit.prevent="onsubmit">
    <va-input
      v-model="user_name"
      type="text"
      label="User Name"
      placeholder="Enter User Name"
      pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
      :error="!!userNameErrors.length"
      :error-messages="userNameErrors"
    />

    <va-input
      v-model="password"
      type="password"
      label="Password"
      placeholder="Enter Password"
      pattern="[A-Za-z0-9_@!./#&+-^]{4,}$"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />
    <div v-if="err_flag" style="color: #f8706d;"><label>{{err_msg}}</label></div>


    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0" style="background-color: #2c82e0;">Login</va-button>
    </div>
  </form>
</template>

<script>
import config from '../../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { navigationRoutes } from '../../admin/app-sidebar/NavigationRoutes'

Vue.use(vueResource)
export default {
  name: 'login',
  created(){
    this.$themes.primary = '#2c82e0'
    this.$themes.info = '#2c82e0'
  },
  data () {
    return {
      user_name: '',
      password: '',
      keepLoggedIn: false,
      userNameErrors: [],
      passwordErrors: [],
      err_flag: false,
      err_msg: '',
    }
  },
  computed: {
    formReady () {
      return !this.userNameErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    onsubmit () {
      this.userNameErrors = this.user_name ? [] : ['User Name is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        return
      }
      this.$http.post(config.menu.host + 'auth/login', { user_name: this.user_name, 
      password: this.password }).then(data => {
        var response = data.body
        Vue.$cookies.remove('selected-customer')
        Vue.$cookies.set('x-access-token', response.token)
        Vue.$cookies.set('user-id', response.userId)
        Vue.$cookies.set('userRole', response.role)
        localStorage.setItem("userRole", response.role)
        var navigation_routes = navigationRoutes.routes_copy
        var user_role = response.role
        var routes_data_by_role = []
        for (var i = 0; i < navigation_routes.length; i++) {
          if (navigation_routes[i].meta.authorize.includes(user_role)) {
            routes_data_by_role.push(navigation_routes[i])
          }
        }
        navigationRoutes.routes = routes_data_by_role
        this.$router.push({ name: 'dashboard' })
      }, err => {
        this.err_flag = true
        this.err_msg = 'Invalid Login Credentials'
      })
    },
  },
}
</script>

<style lang="scss">
</style>

import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'
import Login from '../components/auth/login/Login.vue'
import VueResource from 'vue-resource'
import { navigationRoutes } from '../components/admin/app-sidebar/NavigationRoutes'

Vue.use(VueResource)
Vue.use(Router)
Vue.use(require('vue-cookies'))
//
const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

function loginAuthGuard (to, from, next) {
  var accessToken = Vue.$cookies.get('x-access-token')
  if (accessToken) {
    Vue.$cookies.remove('x-access-token')
    Vue.$cookies.remove('user-id')
  }
  var isAuthenticated = localStorage.getItem('LoggedInUser')
  if (to.meta.isLoggedInCheck) {
    if (isAuthenticated) {
      next('/')
      return
    }
  }
  next()
}

const router = new Router({
  routes: [
    {
      path: '*',
      redirect: { name: 'login' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: Login,
        },
        {
          name: 'signup',
          path: 'signup',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: () => import('../components/auth/signup/Signup.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: () => import('../components/auth/recover-password/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          name: 'org',
          path: 'org',
          component: () => import('../components/org/Org.vue'),
          default: true,
        },
        {
          name: 'users',
          path: 'users',
          component: () => import('../components/user/User.vue'),
          children: [],
        },
        {
          name: 'platform',
          path: 'platform',
          component: () => import('../components/platform/Platform.vue'),
          children: [],
        },
        {
          name: 'platform_kyc',
          path: 'platformkyc',
          component: () => import('../components/platform_kyc/PlatformKyc.vue'),
          children: [],
        },
        {
          name: 'customer_org',
          path: 'customerorg',
          component: () => import('../components/customer_org/CustomerOrg.vue'),
          children: [],
        },
        {
          name: 'customer_org_platform',
          path: 'customerorgplatform',
          component: () => import('../components/customer_org_platform/CustomerOrgPtLst.vue'),
          children: [],
        },
        {
          name: 'license_request',
          path: 'licenserequest',
          props: true,
          component: () => import('../components/license_request/LicenseRequest.vue'),
          children: [],
        },
        {
          name: 'license_request',
          path: 'licenserequest/:id',
          props: true,
          component: () => import('../components/license_request/LicenseRequestDetails.vue'),
          children: [],
        },
        {
          name: 'profile',
          path: 'profile',
          component: () => import('../components/profile/Profile.vue'),
          children: [],
        },
        {
          name: 'customer_kyc',
          path: 'customerkyc',
          component: () => import('../components/customer_kyc/CustomerKyc.vue'),
          children: [],
        },{
          name: 'backup',
          path: 'backup',
          component: () => import('../components/backup/Backup.vue'),
          children: [],
        },
      ],
    },
  ],
})

router.beforeEach((to, _from, next) => {
  const navigation = navigationRoutes.routes.find(route => route.name == to.name)
  if (navigation) next()
  else if (['profile', 'login'].includes(to.name)) next()
  else next({ path: 'dashboard' })
})

export default router

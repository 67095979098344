import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
var routes_data = [
  {
    name: 'dashboard',
    displayName: 'Dashboard',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
  },
  {
    name: 'org',
    displayName: 'Organization',
    meta: {
      iconClass: 'entypo entypo-menu',
      authorize: ['SYS_ADMIN'],
    },
  },
  {
    name: 'users',
    displayName: 'User',
    meta: {
      iconClass: 'fa fa-user',
      authorize: ['SYS_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'platform',
    displayName: 'Platform',
    meta: {
      iconClass: 'entypo entypo-layout',
      authorize: ['SYS_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'platform_kyc',
    displayName: 'Platform Kyc',
    meta: {
      iconClass: 'entypo entypo-doc-text',
      authorize: ['SYS_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'customer_org',
    displayName: 'Customer Organization',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-forms',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
    disabled: true,
  },
  {
    name: 'customer_kyc',
    displayName: 'Customer Org KYC',
    meta: {
      iconClass: 'fa fa-image',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
    disabled: true,
  },
  {
    name: 'customer_org_platform',
    displayName: 'Customer Org Platform',
    meta: {
      iconClass: 'entypo entypo-plus-circled',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
    disabled: true,
  },
  {
    name: 'license_request',
    displayName: 'License Request',
    meta: {
      iconClass: 'entypo entypo-key',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
    disabled: true,
  },{
    name: 'backup',
    displayName: 'Backup',
    meta: {
      iconClass: 'fa fa-database',
      authorize: ['SYS_ADMIN','RESELLER'],
    },
    disabled: true,
  },
]

export let getNavigationRoutes = () => {
  let role = localStorage.getItem('userRole')
  if (!role) return routes_data
  return routes_data.filter(route => {
    if (route.meta.authorize.includes(role)) return true
  })
}

var navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: getNavigationRoutes(),
  routes_copy:routes_data
}

export { navigationRoutes }